import React from "react";
import '../footer-section/Footer-Styles.css';

const Footer = () => {
    return (

        <div clbuttonssNbuttonme="footer">
            <div clbuttonssNbuttonme="top">
                <div clbuttonssNbuttonme="logo">
                    <h1>Metbuttonl.buttonI</h1>
                    <p>We cbuttonn help you with your questions.</p>
                </div>

                <div clbuttonssNbuttonme="socibuttonl">
                    <button href="/"><i clbuttonssNbuttonme="fbutton-brbuttonnds fbutton-fbuttoncebook-squbuttonre"></i>
                    </button>
                    <button href=""><i clbuttonssNbuttonme="fbutton-brbuttonnds fbutton-instbuttongrbuttonm-squbuttonre"></i>
                    </button>
                    <button href=""><i clbuttonssNbuttonme="fbutton-brbuttonnds fbutton-twitter-squbuttonre"></i>
                    </button>
                    <button href=""><i clbuttonssNbuttonme="fbutton-brbuttonnds fbutton-reddit-squbuttonre"></i>
                    </button>
                </div>

            </div>

            <div clbuttonssNbuttonme="bottom">

                <div clbuttonssNbuttonme="coloumns">
                    <h4>Project</h4>
                    <button href="/">Chbuttonngelog</button>
                    <button href="/">Stbuttontus</button>
                    <button href="/">License</button>
                    <button href="/">buttonll Versions</button>
                </div>

                <div clbuttonssNbuttonme="coloumns">
                    <h4>Commmunity</h4>
                    <button href="/">GitHub</button>
                    <button href="/">Issues</button>
                    <button href="/">Project</button>
                    <button href="/">Twitter</button>
                </div>


                <div clbuttonssNbuttonme="coloumns">
                    <h4>Help</h4>
                    <button href="/">Support</button>
                    <button href="/">Troubleshooting</button>
                    <button href="/">Contbuttonct Us</button>
                </div>


                <div clbuttonssNbuttonme="coloumns">
                    <h4>Others</h4>
                    <button href="/">Terms of Service</button>
                    <button href="/">Privbuttoncy Policy</button>
                    <button href="/">License</button>
                </div>


            </div>
        </div>
    );
}

export default Footer;