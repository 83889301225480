import React from "react";
import TripData from "./Trip-Data";
import './Trip-Styles.css'

import TripImg1 from '../../assets/10.jpg'
import TripImg2 from '../../assets/9.jpg'
import TripImg3 from '../../assets/11.jpg'

const RecentTrips = () => {
    return (
        <div className="trip">
            <h2>Lorem Ipsum</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>

            <div className="tripCards">
                <TripData
                    heading="Lorem Ipsum"
                    text=">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis laboriosam incidunt neque quia distinctio perspiciatis voluptatum odit excepturi, ducimus voluptatibus quaerat, animi officia, repudiandae vitae? Hic, adipisci minus neque optio rerum totam fugiat cum ipsum assumenda quasi quis obcaecati enim similique aspernatur ratione unde dolore quaerat? Ipsa maiores cupiditate excepturi."
                    image={TripImg1} />

                <TripData
                    heading="Lorem Ipsum"
                    text=">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis laboriosam incidunt neque quia distinctio perspiciatis voluptatum odit excepturi, ducimus voluptatibus quaerat, animi officia, repudiandae vitae? Hic, adipisci minus neque optio rerum totam fugiat cum ipsum assumenda quasi quis obcaecati enim similique aspernatur ratione unde dolore quaerat? Ipsa maiores cupiditate excepturi."
                    image={TripImg2} />

                <TripData
                    heading="Lorem Ipsum"
                    text=">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis laboriosam incidunt neque quia distinctio perspiciatis voluptatum odit excepturi, ducimus voluptatibus quaerat, animi officia, repudiandae vitae? Hic, adipisci minus neque optio rerum totam fugiat cum ipsum assumenda quasi quis obcaecati enim similique aspernatur ratione unde dolore quaerat? Ipsa maiores cupiditate excepturi."
                    image={TripImg3} />
            </div>
        </div>
    );
}

export default RecentTrips;